<!--
 * @Author: DY
 * @Date: 2021-05-28 10:55:18
 * @LastEditTime: 2021-06-05 14:11:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\productionManagement\productionConsumptionSummary\invoiceDetails.vue
-->
<template>
    <div class="invoiceDetails" v-loading="loading">
        <div class="invoiceInfo">
            <table>
                <tbody>
                    <tr>
                        <th align="right">
                            发货单号：
                        </th>
                        <td>{{ initData.pcb_number }}</td>
                        <th align="right">
                            生产机组：
                        </th>
                        <td>{{ initData.line_name }}</td>
                    </tr>
                    <tr>
                        <th align="right">
                            合同编号：
                        </th>
                        <td>{{ initData.contract_number }}</td>
                        <th align="right">
                            任务单号：
                        </th>
                        <td>{{ initData.task_number }}</td>
                    </tr>
                    <tr>
                        <th align="right">
                            工程名称：
                        </th>
                        <td colspan="3">
                            {{ initData.project_name }}
                        </td>
                    </tr>
                    <tr>
                        <th align="right">
                            实际容重：
                        </th>
                        <td>
                            {{ initData.unit_weight }}
                        </td>
                        <th align="right">
                            产品型号：
                        </th>
                        <td>{{ initData.product_number }}</td>
                    </tr>
                    <tr>
                        <th align="right">
                            司机：
                        </th>
                        <td>{{ initData.sjxm }}</td>
                        <th align="right">
                            本车方量：
                        </th>
                        <td>{{ initData.volume }}</td>
                    </tr>
                    <tr>
                        <th align="right">
                            关联发货单：
                        </th>
                        <td>
                            <span class="link-text" @click="viewRelationPcb(initData.target_pcb_number)">
                                {{ initData.target_pcb_number }}
                            </span>
                        </td>
                        <th align="right">
                            发货日期：
                        </th>
                        <td>
                            {{ initData.pcsj }}
                        </td>
                    </tr>
                    <tr>
                        <th align="right">
                            单位名称：
                        </th>
                        <td colspan="3">
                            {{ initData.sgdw_name }}
                        </td>
                    </tr>
                    <tr>
                        <th align="right">
                            工程地址：
                        </th>
                        <td>
                            {{ initData.project_address }}
                        </td>
                        <th align="right">
                            施工部位：
                        </th>
                        <td>
                            {{ initData.place_name }}
                        </td>
                    </tr>
                    <tr>
                        <th align="right">
                            坍落度：
                        </th>
                        <td>{{ initData.slump }}</td>
                        <th align="right">
                            车号：
                        </th>
                        <td>{{ initData.shch }}</td>
                    </tr>
                    <tr>
                        <th align="right">
                            累计车次：
                        </th>
                        <td>{{ initData.grand_total_vehicle }}</td>
                        <th align="right">
                            质检员：
                        </th>
                        <td>{{ initData.signer }}</td>
                    </tr>
                    <!-- <tr>
                        <th>砂浆方量</th>
                        <td>{{ initData.mortar_volume }}</td>
                    </tr>
                    <tr>
                        <th>累计方量</th>
                        <td>{{ initData.grand_total_quantity }}</td>
                    </tr>
                    <tr>
                        <th>操作员</th>
                        <td>{{ initData.jcy }}</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="consumeTable">
            <div class="head_title">
                <div>
                    <div class="side1"></div>
                    <div class="side2"></div>
                    <span class="title">本车原材料消耗</span>
                    <div class="side2"></div>
                    <div class="side1"></div>
                </div>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                :cell-class-name="cellHight"
                border
            >
                <el-table-column
                    prop="gklcbh"
                    label="料仓号"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="material_child_name"
                    label="原材料"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="material_name"
                    label="规格"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="ylshijyl"
                    label="配比用量(千克)"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="ylsjyl"
                    label="实际用量(千克)"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="error_rate"
                    label="误差率(%)"
                    align="center"
                >
                </el-table-column>
            </el-table>
        </div>
        <footer>
            <el-button @click="cancel">
                关闭
            </el-button>
            <el-button @click="closeAll" v-if="extr.openList">
                全部关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'invoice-details',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String ],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            loading: false,
            initData: {},
            tableData: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        // 初始化页面数据
        this.getInit();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 高亮显示
        cellHight({ row, column, columnIndex }) {
            if (columnIndex === 5) {
                if (row.highlight === '1') {
                    return 'hight-cell';
                }
            }
            return '';
        },
        cancel() {
            this.$parent.hide();
        },
        // 初始化页面数据
        getInit() {
            this.$axios
                .get(`/interfaceApi/production/productionexternal/producttask/detail/${this.extr.pcb_number}`)
                .then(res => {
                    if (res) {
                        this.initData = res;
                        this.tableData = res.Consumes;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 查看关联发货单详情
        viewRelationPcb(pcb_nubmer) {
            const openList = [...this.extr.openList || []];
            openList.push(this.$parent);
            this.$toast({
                title: true,
                text: '发货单详情',
                type: 'eject',
                width: '70%',
                height: 'auto',
                t_url: 'productionManagement/productionConsumptionSummary/invoiceDetails',
                extr: {
                    pcb_number: pcb_nubmer,
                    openList: openList,
                },
            });
        },
        closeAll() {
            this.$parent.hide();
            (this.extr.openList || []).forEach(item => {
                item.hide();
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.invoiceDetails
    padding 0.14rem
    display flex
    flex-wrap wrap
    justify-content space-between
    >div
        width calc((100% - 0.14rem)/2)
    .invoiceInfo
        table
            width 100%
            border-collapse collapse
            th,td
                border 1px solid #D7D7D7
                font-size 0.14rem
                line-height 0.4rem
                font-weight 400
            th
                width 1.1rem
                background #F8F8F8
            td
                width 2.4rem
                padding-left 0.2rem
                .link-text
                    color: blue;
                    text-decoration: underline;
                    cursor: pointer;
    .consumeTable
        height 3.6rem
        >>> .el-table
                th,td
                    height 0.4rem
                    padding 0
                th
                    text-align center
                    background #F8F8F8
                    .cell
                        font-size 0.14rem
                td
                    font-size 0.14rem
                .hight-cell
                    .cell
                        border 1px solid #FC9545
                        margin .02rem
                        background #FFF7F2

    footer
        width 100%
        text-align center
        height 0.6rem
        margin-top 0.3rem
        .el-button
            width 2.2rem
            font-size 0.16rem
            line-height 0.4rem
            margin 0 0.2rem
            padding 0
</style>
